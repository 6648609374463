var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-app flex-row align-items-center",
      class: { "c-dark-theme": _vm.$store.state.darkMode },
    },
    [
      _c(
        "CContainer",
        [
          _c(
            "CRow",
            { staticClass: "justify-content-center" },
            [
              _c(
                "CCol",
                { staticClass: "p-4 text-center", attrs: { md: "8" } },
                [
                  _c("img", {
                    staticClass: "img-drop-shadow",
                    attrs: { src: "/img/menute.be/logo.png" },
                  }),
                ]
              ),
              _c(
                "CCol",
                { staticClass: "mt-4", attrs: { md: "8" } },
                [
                  _c("alert-section", {
                    attrs: {
                      successAlertMessage: _vm.successAlertMessage,
                      dismissSecs: _vm.dismissSecs,
                      dismissSuccessAlert: _vm.dismissSuccessAlert,
                      errorAlertMessage: _vm.errorAlertMessage,
                      showErrorAlert: _vm.showErrorAlert,
                    },
                  }),
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c(
                    "CCardGroup",
                    [
                      _c(
                        "CCard",
                        { staticClass: "p-4" },
                        [
                          _c(
                            "CCardBody",
                            [
                              !_vm.isLoggedIn
                                ? _c(
                                    "CForm",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.login.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("h2", [_vm._v("Login")]),
                                      _c("p", { staticClass: "text-muted" }, [
                                        _vm._v("Sign In to your account"),
                                      ]),
                                      _c("CInput", {
                                        attrs: {
                                          placeholder: "E-mail",
                                          autocomplete: "username email",
                                          lazy: false,
                                          value: _vm.$v.form.email.$model,
                                          isValid: _vm.checkIfValid(
                                            "form",
                                            "email"
                                          ),
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              _vm.$v.form.email,
                                              "$model",
                                              $event
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend-content",
                                              fn: function () {
                                                return [
                                                  _c("CIcon", {
                                                    attrs: { name: "cil-at" },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3652513961
                                        ),
                                      }),
                                      _c("CInput", {
                                        attrs: {
                                          placeholder: "Password",
                                          type: "password",
                                          autocomplete: "curent-password",
                                          lazy: false,
                                          value: _vm.$v.form.password.$model,
                                          isValid: _vm.checkIfValid(
                                            "form",
                                            "password"
                                          ),
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              _vm.$v.form.password,
                                              "$model",
                                              $event
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend-content",
                                              fn: function () {
                                                return [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cil-lock-locked",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3300492400
                                        ),
                                      }),
                                      _c(
                                        "CRow",
                                        [
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "text-left",
                                              attrs: { col: "6" },
                                            },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  staticClass: "px-4 bg-menute",
                                                  attrs: {
                                                    type: "submit",
                                                    disabled: _vm.submitted,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.login()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Login")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "text-right",
                                              attrs: { col: "6" },
                                            },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  staticClass: "px-0",
                                                  attrs: { color: "link" },
                                                },
                                                [_vm._v("Forgot password?")]
                                              ),
                                              _c(
                                                "CLink",
                                                {
                                                  staticClass: "d-lg-none",
                                                  staticStyle: {
                                                    color: "#d60b52",
                                                  },
                                                  attrs: {
                                                    href: "https://www.menute.be/#nav/#login/#register",
                                                    target: "_blank",
                                                  },
                                                },
                                                [_vm._v(" Register Now! ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "CForm",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.verifyOtp.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("h2", [_vm._v("2-Step Verification")]),
                                      _c("p", { staticClass: "text-muted" }, [
                                        _vm._v(
                                          "A text message with a 6-digit verification code was just sent to " +
                                            _vm._s(_vm.masked2FANumber)
                                        ),
                                      ]),
                                      _c("CInput", {
                                        attrs: {
                                          placeholder: "Enter the code",
                                          lazy: false,
                                          value: _vm.$v.otpForm.otp.$model,
                                          isValid: _vm.checkIfValid(
                                            "otpForm",
                                            "otp"
                                          ),
                                          type: "number",
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              _vm.$v.otpForm.otp,
                                              "$model",
                                              $event
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "prepend-content",
                                            fn: function () {
                                              return [
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-asterisk",
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                      _c(
                                        "CRow",
                                        [
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "text-left",
                                              attrs: { col: "6" },
                                            },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  staticClass: "px-4",
                                                  attrs: {
                                                    type: "submit",
                                                    color: "warning",
                                                    disabled: _vm.submitted,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.verifyOtp()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Verify")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "text-right",
                                              attrs: { col: "6" },
                                            },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  staticClass: "px-0",
                                                  attrs: { color: "link" },
                                                },
                                                [_vm._v("Resend code")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCard",
                        {
                          staticClass: "text-center py-5 d-md-down-none",
                          class: `bg-${!_vm.isLoggedIn ? "menute" : "warning"}`,
                          attrs: { "body-wrapper": "" },
                        },
                        [
                          !_vm.isLoggedIn
                            ? _c(
                                "CCardBody",
                                [
                                  _c("h2", [_vm._v("Sign up")]),
                                  _c("p", [
                                    _vm._v(
                                      "Order food online via Menute.be, the fastest growing order portal in Belgium."
                                    ),
                                  ]),
                                  _c(
                                    "CLink",
                                    {
                                      staticClass:
                                        "btn btn-outline-light btn-lg",
                                      attrs: {
                                        href: "https://www.menute.be/#nav/#login/#register",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Register Now!")]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "CCardBody",
                                [
                                  _c("h5", [_vm._v("Back to login")]),
                                  _c(
                                    "CLink",
                                    {
                                      staticClass: "btn btn-outline btn-lg",
                                      on: {
                                        click: () =>
                                          (_vm.isLoggedIn = !_vm.isLoggedIn),
                                      },
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: {
                                          name: "cil-arrow-left",
                                          size: "xl",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("CElementCover", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.submitted,
                        expression: "submitted",
                      },
                    ],
                    attrs: { opacity: 0.4 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }